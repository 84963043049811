import * as React from 'react';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

import * as S from './_style';
import * as T from './_types';
import * as E from './_effects';

import { IconButton } from '../../../../IconButton';
// import { Button } from '../../../../IconButton/_style';
import { Button, ButtonDesign } from '../../../../../components/Button/';

export const DetailsUpload: React.FC<T.DetailsUploadProps> = ({
  style,
  className,
  id,
  showPause,
}) => {
  const { paused, pause, unpause, progress, stagnant } = E.useDetailsUpload(id);

  return (
    <>
      {stagnant && (
        <S.UploadPercentage>
          There are problems with your upload. Please check your internet connection.
        </S.UploadPercentage>
      )}
      {showPause && (
        <>
          Note, that{' '}
          <strong>refreshing or leaving the page will cause the upload to abort</strong>.
          Contact support support if this accidentally happened.
          <br />
          <br />
        </>
      )}
      {showPause && paused && (
        <>
          Upload is currently being paused:{' '}
          <Button design={ButtonDesign.LINK} label="Resume upload" onClick={unpause} />
        </>
      )}
      {showPause && !paused && (
        <>
          Upload is ongoing:{' '}
          <Button design={ButtonDesign.LINK} label="Pause upload" onClick={pause} />
        </>
      )}
    </>
  );
};

DetailsUpload.displayName = 'DetailsUpload';
