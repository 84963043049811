import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import UnstyledDropDownList from './drop-down-list';
import { OnChangeType, Option } from '../../combinator/ExperimentSelector';
import { InputSearchFilter } from '../input';
import { Button, ButtonDesign } from '../../Button';
import { IconFilter } from '../../icons';

const DropDownList = styled(UnstyledDropDownList)`
  height: calc(100vh - 200px);
  overflow-y: scroll;
  border-right: 1px solid ${themeGet('colors.grey90')};
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding-top: ${themeGet('space.3')};
`;

export const WellAlignedDiv = styled.div`
  padding: ${themeGet('space.3')} 0;
  border-right: 1px solid ${themeGet('colors.grey90')};

  > svg  {
    vertical-align: bottom;
    padding: 0 ${themeGet('space.1')};
  }
`;

type SearchSelectProps = {
  options: Option[];
  onChange: OnChangeType;
  disabled?: boolean;
  onError?: () => void;
  children?: React.ReactNode;
};

const SearchSelect = ({
  options,
  onChange,
  disabled = false,
  onError,
  children,
}: SearchSelectProps) => {
  let [inputElement, setInputElement] = useState<HTMLInputElement | null>(null);
  let [inputValue, setInputValue] = useState<string>('');
  let [focusIndex, setFocusIndex] = useState<number>(-1);
  const [upRankFavorites, setSortFavoritesTop] = useState(false);
  let [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

  const handleTextInput: ChangeEventHandler<{ value: string }> = ({
    target: { value: textInputValue },
  }) => {
    const search = inputValue.toLowerCase();
    const filteredOptions = options.filter(({ label, description, metadata_tags }) => {
      console.log(description);
      console.log(metadata_tags);
      if (textInputValue === '') return true;
      if (!!label && label.toLowerCase().indexOf(search) !== -1) return true;
      if (!!description && description.toLowerCase().indexOf(search) !== -1) return true;
      if (
        !!metadata_tags &&
        metadata_tags.some((tag) => tag.toLowerCase().indexOf(search) !== -1)
      )
        return true;
      return false;
    });

    setInputValue(textInputValue);
    setFilteredOptions(filteredOptions);
    setFocusIndex(
      focusIndex === -1
        ? -1
        : focusIndex > filteredOptions.length
        ? filteredOptions.length
        : 0,
    );
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ keyCode }) => {
    const changeFocus = (diff: number) =>
      setFocusIndex(
        (focusIndex + diff + filteredOptions.length) % filteredOptions.length,
      );

    switch (keyCode) {
      case 38:
        changeFocus(-1);
        return;
      case 40:
        changeFocus(1);
        return;
      case 13: // enter
        if (filteredOptions.length) {
          const selectedIndex = focusIndex !== -1 ? focusIndex : 0;
          const value = filteredOptions[selectedIndex].value;
          onChange(value ? value : '');
        } else if (onError) {
          onError();
        }

        if (inputElement) inputElement.blur();

        setFocusIndex(-1);
        setInputValue('');
        setFilteredOptions([...options]);

        return;
      default:
      // unhandled keys
    }
  };

  return (
    <Container>
      {!!children && children}

      <InputSearchFilter
        placeholder={'Search by name, description or tags'}
        disabled={disabled}
        value={inputValue}
        onChange={handleTextInput}
        onKeyDown={handleKeyDown}
        ref={(inputElement) => setInputElement(inputElement)}
      />
      <WellAlignedDiv>
        <IconFilter />
        <Button
          onClick={() => setSortFavoritesTop(!upRankFavorites)}
          design={ButtonDesign.LINK}
        >
          {upRankFavorites ? 'Show all' : 'Show starred'}
        </Button>
      </WellAlignedDiv>
      {!disabled && (
        <DropDownList
          options={filteredOptions.filter(
            (dataset) => !upRankFavorites || (dataset.favorite && upRankFavorites),
          )}
          onChange={onChange}
          filter={inputValue}
          focusIndex={focusIndex}
        />
      )}
    </Container>
  );
};

export default SearchSelect;
