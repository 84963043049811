import { SpaceProps } from 'styled-system';

export enum ButtonDesign {
  DEFAULT = 'default',
  HIGHLIGHT = 'highlight',
  DANGER = 'danger',
  LINK = 'link',
  LIGHT = 'light',
  MINIATURE = 'mini',
}

export interface ButtonContainerProps extends SpaceProps {
  focused: boolean;
  hovered: boolean;
  design: ButtonDesign;
}
