import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import * as T from './_types';

export const IconContainer = styled.span`
  transition: color 250ms ease-out;
  line-height: 0;
`;

export const Label = styled.span`
  transition: color 250ms ease-out;
  font-family: Nunito, Frutiger, Helvetica, Arial, sans-serif;
  font-size: ${themeGet('fontSize.1')};
  font-weight: bold;
`;

const containerRound = css<T.ContainerProps>`
  background: white;

  &::before {
    border: 1px solid
      ${(props) => (props.disabled ? `transparent` : themeGet('colors.grey20'))};
    border-radius: 100vmax;
    top: 0;
    height: 100%;
  }

  ${Label} {
    font-size: ${themeGet('fontSizes.0')};
    text-transform: capitalize;
  }
`;

const containerTab = css<T.ContainerProps>`
  &::before {
    height: 3px;
    bottom: 0;
    background: ${themeGet('colors.action')};
    z-index: 2;
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: ${themeGet('colors.backgroundAlt')};
    z-index: 1;
  }
`;

export const SuperContainer = styled.div`
  border: 1px solid ${themeGet(`colors.grey20`)};
  border-radius: 100vmax;

  padding: 2px;
`;

export const Container = styled.div<T.ContainerProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 100vmax;

  &::before {
    content: ' ';
    display: block;
    width: ${(props) => props.selectionWidth};
    left: 0;
    position: absolute;
    transform: translateX(${(props) => props.translationX});
    opacity: ${(props) => (props.selectionIndex === -1 ? 0 : 1)};
    transition: all 250ms ease-out, opacity 250ms 100ms ease-out, transform 250ms ease-out;
    box-sizing: border-box;
    background: ${themeGet(`colors.gold`)};
  }

  ${(props) =>
    ({
      [T.ToggleStyle.Round]: containerRound,
      [T.ToggleStyle.Tab]: containerTab,
    }[props.design])}
`;

const optionRound = css<T.OptionProps>`
  border: 0;
  border-radius: 100vmax;
  color: ${themeGet(`colors.grey20`)};
  font-weight: 600;
  text-transform: uppercase;

  &:focus,
  &:not([disabled]):hover {
    background: ${(props) => (props.selected ? 'transparent' : 'transparent')};
  }

  &:focus {
  }
`;

const optionTab = css<T.OptionProps>`
  min-height: 3em;
  margin: 0;
  border: 0;
  font-size: 18px;

  ${IconContainer},
  ${Label} {
    font-size: ${themeGet('fontSizes.1')};
    color: ${(props) =>
      props.selected ? themeGet('colors.action') : themeGet('colors.backgroundAlt')};
  }

  &:focus,
  &:not([disabled]):hover {
    background: transparent;
  }

  &:focus ${Label}, &:focus ${IconContainer}, &:hover ${Label}, &:hover ${IconContainer} {
    color: ${themeGet('colors.action')};
  }
`;

export const Option = styled.button<T.OptionProps>`
  flex-grow: 1;
  width: content-box;
  background: transparent;
  box-sizing: border-box;
  z-index: 1;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px ${themeGet('space.3')};
  transition: background 200ms ease-out, color 250ms ease-out;

  &:focus {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &[disabled] {
    cursor: default;
    opacity: 1;
  }

  ${(props) =>
    ({
      [T.ToggleStyle.Round]: optionRound,
      [T.ToggleStyle.Tab]: optionTab,
    }[props.design])}
`;
