import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';

import { HighlightText } from '../../decoration/highlight-text';
import { StarIcon, BlankIcon12by12 } from '../../icons';
import { Button, ButtonDesign } from '../../Button';

const optionFocusCSS = css`
  outline: 2px solid ${themeGet('colors.focus')};
  outline-offset: -2px;
`;

const Container = styled.div`
  background: ${themeGet('colors.background')};
`;

const Option = styled.div`
  background: ${themeGet('colors.background')};
  padding: ${themeGet('space.2')} ${themeGet('space.2')};

  ${({ focus }) => focus && optionFocusCSS};

  &:hover {
    cursor: pointer;
    background: ${themeGet('colors.grey90')};
  }

  &:nth-child(n + 2) {
    border-top: 1px solid ${themeGet('colors.grey90')};
  }
`;

const FlexBoxed = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0;
  padding-bottom: 0;
  gap: 5px;
`;

const Description = styled.div`
  color: ${themeGet('colors.grey50')};
  margin: ${themeGet('space.1')} 0 auto auto;
  font-size: ${themeGet('fontSizes.0')};
`;

const InsetBoxToOptionList = styled.div`
  padding: 0 ${themeGet('space.4')} ${themeGet('space.2')};
`;

const MoreLessComponent = ({ truncatedText, fullText, highlight }) => {
  const [more, setMore] = React.useState(false);
  return (
    <InsetBoxToOptionList>
      <Description>
        <HighlightText
          text={!more ? `${truncatedText}...` : fullText}
          highlight={highlight}
        ></HighlightText>
        <Button onClick={() => setMore(!more)} design="mini">
          <>{more ? 'less' : 'more'}</>
        </Button>
      </Description>
    </InsetBoxToOptionList>
  );
};

const renderOption = ({ focusIndex, filter, onChange }) => (
  { value, label, description, favorite },
  index,
) => (
  <>
    <Option
      key={value + filter}
      onClick={() => onChange(value)}
      onMouseDown={() => onChange(value)}
      focus={focusIndex === index}
    >
      <FlexBoxed>
        {favorite ? <StarIcon fill={favorite} /> : <BlankIcon12by12 />}
        <div>
          <HighlightText text={label} highlight={filter} />
        </div>
      </FlexBoxed>
    </Option>
    <MoreLessComponent
      truncatedText={description.substring(0, 100)}
      fullText={description}
      highlight={filter}
    />
  </>
);

export const DropDownList = ({ className, options, onChange, filter, focusIndex }) => (
  <Container className={className}>
    {options.map(renderOption({ focusIndex, filter, onChange }))}
  </Container>
);

export default DropDownList;

DropDownList.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      favorite: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.string,
  focusIndex: PropTypes.number,
};

DropDownList.defaultProps = {
  className: null,
  filter: null,
  focusIndex: -1,
};
