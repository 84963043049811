import React from 'react';

import * as S from './_style';

import { MenuLink } from './MenuLink';
import styled from 'styled-components';
import { Link } from '../../../Link';

export interface MenuBarProps {
  style?: React.CSSProperties;
  className?: string;
}

export const HomeButton = styled.a`
  text-decoration: none;
  border-bottom: none;
  border-right: #eee 1px solid;
`;

export const MenuBar: React.FC<MenuBarProps> = ({ style, className }) => (
  <S.Container style={style} className={className}>
    <HomeButton href="/">
      <img width="40px" src="/images/knowing01.svg" alt="knowing01 logo" />
    </HomeButton>
    <MenuLink to="/search" isBeta={true}>
      Search
    </MenuLink>
    <MenuLink to="/explore">Explore</MenuLink>
    <MenuLink to="/datasets">Data center</MenuLink>
    <MenuLink to="/downloads">Download center</MenuLink>
    <MenuLink to="/method-description" stretchLeft>
      Info
    </MenuLink>
    <MenuLink to="/account">Account</MenuLink>
    <MenuLink highlight to="/logout">
      Sign out
    </MenuLink>
  </S.Container>
);

MenuBar.displayName = 'MenuBar';
