import React from 'react';

import * as S from './_style';
import * as T from './_types';

import { useMultiToggle } from './_effects';

export interface MultiToggleProps {
  style?: React.CSSProperties;
  className?: string;
  value?: T.ToggleValue;
  options: T.Option[];
  onChange: (value: any) => void;
  gap?: string;
  disabled?: boolean;
  design?: T.ToggleStyle;
}

export const MultiToggle: React.FC<MultiToggleProps> = ({
  className,
  style,
  value = null,
  gap = '.25rem',
  disabled = false,
  options,
  onChange,
  design = T.ToggleStyle.Round,
}) => {
  const { selectionIndex, translationX, ref, width } = useMultiToggle(
    options,
    value,
    gap,
  );
  return (
    <S.SuperContainer>
      <S.Container
        className={className}
        selectionWidth={width}
        selectionIndex={selectionIndex}
        translationX={translationX}
        style={style}
        disabled={disabled}
        ref={ref === null ? undefined : ref}
        design={design}
      >
        {options.map(({ value, label, icon }, index) => (
          <S.Option
            disabled={disabled}
            selected={selectionIndex === index}
            tabIndex={selectionIndex === index ? -1 : undefined}
            key={value}
            onClick={() => onChange(value)}
            style={{ width, marginLeft: index > 0 ? gap : 0 }}
            design={design}
          >
            {icon && <S.IconContainer>{icon}</S.IconContainer>}
            {label && <S.Label>{label}</S.Label>}
          </S.Option>
        ))}
      </S.Container>
    </S.SuperContainer>
  );
};
