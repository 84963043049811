import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { AnyAction, ExperimentCutoffType } from './_types';
import { Dataset, DatasetItemStatus } from '../components/Pages/Datasets';
import { RawFile } from '../components/CellMap';
import { AppState } from '../store';
import {
  deleteRawFile,
  readRawFiles,
  setCutoffLax,
  setCutoffStringent,
  setFavoriteToggle,
} from '../services/api';
import { uploadComplete } from './upload';

// variables and constants required for proper/nonclotting /file request from API
const DATA_SET_UPDATE_DELAY = 1000;
const DATA_SET_FETCH_MULTIPLE_ACTIVE = 2;
const DATA_SET_FETCH_MULTIPLE_STALE = 9;
let data_set_update_counter = 0;
let data_set_update_in_stale_mode = false;
let freeToGoFetchingFile = true;

// Actions
export const dataSetsLoad = () => ({
  type: 'DATA_SETS_LOAD',
});

export const dataSetsLoadError = (errorMessage: string) => ({
  type: 'DATA_SETS_LOAD_ERROR',
  payload: { errorMessage },
});

export const dataSetsLoadSuccess = (dataSets: Dataset[]) => ({
  type: 'DATA_SETS_LOAD_SUCCESS',
  payload: { dataSets },
});

export const dataSetsRemove = (id: string) => ({
  type: 'DATA_SETS_REMOVE',
  payload: { id },
});

export const dataSetsRemoveError = (id: string, errorMessage: string) => ({
  type: 'DATA_SETS_REMOVE',
  payload: { id, errorMessage },
});

export const dataSetsRemoveSuccess = (id: string) => ({
  type: 'DATA_SETS_REMOVE_SUCCESS',
  payload: { id },
});

export const setCutoff = (
  cutoffType: ExperimentCutoffType,
  experimentId: string,
  cutoffIndex: number,
) => ({
  type: 'SET_CUTOFF',
  payload: { cutoffType, experimentId, cutoffIndex },
});

export const toggleFavorite = (experimentId: string) => ({
  type: 'TOGGLE_FAVORITE',
  payload: { experimentId },
});

export const resetDataSetsState = () => ({
  type: 'RESET_DATA_SETS_STATE',
});

export const setHighlightedDatasets = (highlightedDatasetIds: string[]) => ({
  type: 'SET_HIGHLIGHTED_DATASETS',
  payload: highlightedDatasetIds,
});

export const clearHighlightedDatasets = () => ({
  type: 'CLEAR_HIGHLIGHTED_DATASETS',
});

export type DataSetsAction =
  | ReturnType<typeof dataSetsLoad>
  | ReturnType<typeof dataSetsLoadError>
  | ReturnType<typeof dataSetsLoadSuccess>
  | ReturnType<typeof dataSetsRemove>
  | ReturnType<typeof dataSetsRemoveSuccess>
  | ReturnType<typeof dataSetsRemoveError>
  | ReturnType<typeof setCutoff>
  | ReturnType<typeof toggleFavorite>
  | ReturnType<typeof setHighlightedDatasets>
  | ReturnType<typeof clearHighlightedDatasets>;

// State
export interface DataSetsState {
  loading: boolean;
  errorMessage?: string;
  dataSets: Dataset[];
  highlightedDatasets: string[];
}

const defaultState: DataSetsState = {
  loading: false,
  dataSets: [],
  highlightedDatasets: [],
};

// Reducer
const reducer = (
  state: DataSetsState = defaultState,
  action: AnyAction,
): DataSetsState => {
  switch (action.type) {
    case 'DATA_SETS_LOAD':
      return {
        ...state,
        loading: true,
      };
    case 'DATA_SETS_LOAD_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof dataSetsLoadError>;
      return {
        ...state,
        loading: false,
        errorMessage,
      };
    }
    case 'DATA_SETS_LOAD_SUCCESS': {
      const {
        payload: { dataSets },
      } = action as ReturnType<typeof dataSetsLoadSuccess>;
      return {
        ...state,
        errorMessage: undefined,
        loading: false,
        dataSets,
      };
    }
    case 'DATA_SETS_REMOVE': {
      const {
        payload: { id },
      } = action as ReturnType<typeof dataSetsRemove>;
      return {
        ...state,
        dataSets: state.dataSets
          ? state.dataSets.filter((dataSet) => dataSet.id !== id)
          : state.dataSets,
      };
    }
    case 'SET_CUTOFF': {
      const {
        payload: { cutoffType, experimentId, cutoffIndex },
      } = action as ReturnType<typeof setCutoff>;
      return {
        ...state,
        dataSets: state.dataSets.map((dataSet) => {
          if (dataSet.experiment_id === experimentId) {
            if (cutoffType === ExperimentCutoffType.LAX) {
              dataSet.cutoff_lax = dataSet.available_cutoffs[cutoffIndex];
            } else if (cutoffType === ExperimentCutoffType.STRINGENT) {
              dataSet.cutoff_stringent = dataSet.available_cutoffs[cutoffIndex];
            }
          }

          return dataSet;
        }),
      };
    }
    case 'TOGGLE_FAVORITE': {
      const {
        payload: { experimentId },
      } = action as ReturnType<typeof toggleFavorite>;
      return {
        ...state,
        dataSets: state.dataSets.map((dataSet) => {
          if (dataSet.experiment_id === experimentId) {
            dataSet.favorite = !dataSet.favorite;
          }
          return dataSet;
        }),
      };
    }
    case 'UPLOAD_COMPLETE': {
      const {
        payload: { id },
      } = action as ReturnType<typeof uploadComplete>;
      return {
        ...state,
        dataSets: state.dataSets.map((dataSet) => {
          if (dataSet.id === id) {
            return {
              ...dataSet,
              status: DatasetItemStatus.IMPORTING,
            };
          }
          return dataSet;
        }),
      };
    }
    case 'SET_HIGHLIGHTED_DATASETS': {
      const { payload } = action as ReturnType<typeof setHighlightedDatasets>;
      return {
        ...state,
        highlightedDatasets: payload,
      };
    }
    case 'CLEAR_HIGHLIGHTED_DATASETS':
      return {
        ...state,
        highlightedDatasets: [],
      };
    case 'AUTH_LOGOUT':
      return defaultState;
    default:
      return state;
  }
};

export default reducer;

// Sagas

const rawFileToDataSet = (rawFile: RawFile): Dataset => ({
  id: rawFile.id,
  fileName: rawFile.file_name,
  status: rawFile.state,
  importProgress: rawFile.import_progress,
  linkProgress: rawFile.link_progress,
  uploadProgress: rawFile.upload_progress,
  name: rawFile.format_parameters.name,
  description: rawFile.format_parameters.description,
  biotype: rawFile.format_parameters.biotype,
  header: rawFile.header,
  sample: rawFile.sample,
  columnSelect: rawFile.format_parameters.columnSelect,
  genomeAssembly: rawFile.format_parameters.genomeAssembly,
  taxonomy: rawFile.format_parameters.taxonomy,
  cutoff_lax: rawFile.cutoff_lax,
  cutoff_stringent: rawFile.cutoff_stringent,
  available_cutoffs: rawFile.available_cutoffs,
  has_fake_significance: rawFile.has_fake_significance,
  experiment_id: rawFile.experiment_id,
  statistics: rawFile.statistics,
  file_import_statistics: rawFile.import_statistics,
  favorite: rawFile.favorite,
  post_processing: rawFile.post_processing,
  metadata_tags: rawFile.format_parameters.metadata_tags,
});

function* handleDataSetsLoad() {
  const idToken = yield select((state: AppState) => state.auth.idToken);

  try {
    const rawFiles = yield call(readRawFiles, idToken);
    yield put(dataSetsLoadSuccess(rawFiles.map(rawFileToDataSet)));
    freeToGoFetchingFile = true;
  } catch (e) {
    yield put(dataSetsLoadError(e.message || e.errorMessage || 'Unknown error'));
  }
}

function* watchDataSetsLoad() {
  yield takeEvery('DATA_SETS_LOAD', handleDataSetsLoad);
}

function* periodicallyUpdateDataSets() {
  while (true) {
    const isLoggedIn = yield select((state: AppState) => state.auth.isLoggedIn);
    if (isLoggedIn) {
      const isProcessingDataSets = yield select((state: AppState) =>
        state.dataSets.dataSets.some(
          (dataSet) =>
            dataSet.status !== DatasetItemStatus.INTEGRATED &&
            dataSet.status !== DatasetItemStatus.ERROR,
        ),
      );
      data_set_update_in_stale_mode = isProcessingDataSets == false;
      // delay depending on the status
      if (
        (data_set_update_in_stale_mode && isProcessingDataSets == true) ||
        (isProcessingDataSets == true &&
          data_set_update_counter == DATA_SET_FETCH_MULTIPLE_ACTIVE) ||
        data_set_update_counter == DATA_SET_FETCH_MULTIPLE_STALE
      ) {
        if (freeToGoFetchingFile) {
          freeToGoFetchingFile = false;
          yield put(dataSetsLoad());
        }

        data_set_update_counter = 0;
      }
      data_set_update_counter += 1;
      yield delay(DATA_SET_UPDATE_DELAY);
    } else {
      yield delay(DATA_SET_UPDATE_DELAY);
    }
  }
}

function* handleDataSetsRemove(action: ReturnType<typeof dataSetsRemove>) {
  const idToken = yield select((state: AppState) => state.auth.idToken);
  const { id } = action.payload;

  try {
    yield call(deleteRawFile, idToken, id);
    yield put(dataSetsRemoveSuccess(id));
  } catch (e) {}
}

function* watchDataSetsRemove() {
  yield takeEvery('DATA_SETS_REMOVE', handleDataSetsRemove);
}

function* handleSetCutoff(action: ReturnType<typeof setCutoff>) {
  const idToken = yield select((state: AppState) => state.auth.idToken);
  const { cutoffType, experimentId, cutoffIndex } = action.payload;
  const postData = {
    experiment_id: experimentId,
    cutoff_index: cutoffIndex,
  };

  try {
    if (cutoffType === ExperimentCutoffType.LAX)
      yield call(setCutoffLax, idToken, postData);
    if (cutoffType === ExperimentCutoffType.STRINGENT)
      yield call(setCutoffStringent, idToken, postData);
  } catch (e) {}
}

function* watchSetCutoff() {
  yield takeEvery('SET_CUTOFF', handleSetCutoff);
}

function* handleToggleFavorite(action: ReturnType<typeof setCutoff>) {
  const idToken = yield select((state: AppState) => state.auth.idToken);
  const { experimentId } = action.payload;
  const postData = {
    experiment_id: experimentId,
  };

  try {
    yield call(setFavoriteToggle, idToken, postData);
  } catch (e) {}
}

function* watchToggleFavorite() {
  yield takeEvery('TOGGLE_FAVORITE', handleToggleFavorite);
}

export const dataSetsSagas = [
  watchDataSetsLoad(),
  watchDataSetsRemove(),
  watchSetCutoff(),
  watchToggleFavorite(),
  periodicallyUpdateDataSets(),
];
