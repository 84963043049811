import styled, { css } from 'styled-components';
import { compose, space, gridArea, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const Input = styled.input<{ hideInvalid?: boolean }>`
  padding: 0.5rem 0.25rem;
  font-size: 1rem;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${themeGet('colors.grey70')};
  border-radius: ${(props) => props.theme.radii.small};
  ${compose(layout, space, gridArea)};

  :focus {
    outline: 0;
    border-color: ${themeGet('colors.grey10')};
  }

  &[readonly] {
    background: ${themeGet('colors.grey90')};
    color: ${themeGet('colors.grey30')};
    border-color: ${themeGet('colors.grey30')};
  }

  :invalid {
    ${(props) =>
      !props.hideInvalid &&
      css`
        border-color: ${themeGet('colors.error')};
        box-shadow: none;
      `}
  }
`;

export const InputSearchFilter = styled(Input)`
  box-sizing: border-box;
  width: calc(100% - ${themeGet('space.1')});
  margin-top: ${themeGet('space.2')};
  margin-bottom: ${themeGet('space.1')};
  margin-left: 0;
  margin-right: ${themeGet('space.2')};
  padding: ${themeGet('space.1')} ${themeGet('space.5')} ${themeGet('space.1')}
    ${themeGet('space.2')};
  height: 3rem;
  border: 2px solid ${themeGet('colors.grey99')};

  &::placeholder {
    color: ${themeGet('colors.grey50')};
  }
`;

export const InputComponentSearch = styled(Input)`
  margin: 0 ${themeGet('space.4')} 0 0 ;
  padding: ${themeGet('space.2')} ${themeGet('space.5')} ${themeGet('space.2')}
    ${themeGet('space.2')};
    border: 2px solid ${themeGet('colors.black')};
  width: 80%;
  border-radius: ${themeGet('radii.large')};%;



`;
