import { DatasetItemStatus, ImportFileError } from '../Pages/Datasets';
import { UploadFormValues } from '../Pages/Datasets/Upload/Upload';
import { ColumnTag } from '../Pages/Datasets/Upload/FileColumnSelector/_data';
import {
  ExperimentCutoffType,
  ExperimentOverlapType,
  selectOperation,
  UserRequestState,
} from '../../store';
import { string } from 'prop-types';

export interface UserDataUpdate {
  company: string;
  contactAgreement: boolean;
}

export interface PasswordChange {
  idToken: string;
  passwordNew: string;
  passwordOld: string;
}

export interface RawFile {
  id: string;
  file_name: string;
  state: DatasetItemStatus;
  import_progress: number;
  link_progress: number;
  upload_progress: number;
  format_parameters: {
    biotype: number;
    columnSelect: { [key: string]: number };
    description: string;
    name: string;
    filetypeInfo?: boolean;
    genomeAssembly?: number;
    taxonomy?: number;
    metadata_tags?: string;
  };
  header: string[];
  sample: string[][];
  cutoff_lax: ExperimentCutoff;
  cutoff_stringent: ExperimentCutoff;
  available_cutoffs: ExperimentCutoff[];
  has_fake_significance?: boolean;
  experiment_id: string;
  statistics: ExperimentStatistics;
  import_statistics: FileImportStatistics;
  info_message: string;
  favorite: boolean;
  post_processing: PostProcessingInfo[];
}

export interface FilePostData {
  idToken: string;
  fileName: string;
  fileSize: number;
  formValues: UploadFormValues;
}

export interface FilePostParameters {
  biotype: number;
  columnSelect: { [key: string]: number };
  description: string;
  name: string;
  metadata_tags: string;
  genomeAssembly?: number;
  taxonomy?: number;
}

export interface FilePatchData {
  idToken: string;
  fileName: string;
  fileSize: number;
  chunkStart: number;
  chunkEnd: number;
  blob: Blob;
}

export interface DataSetFile {
  bytes_received: number;
  id: string;
  detail?: string;
}

export interface ExperimentRaw {
  id: string;
  name: string;
  description: string;
  biotype: string;
  cutoff_lax: ExperimentCutoff;
  cutoff_stringent: ExperimentCutoff;
  statistics: ExperimentStatistics;
  favorite: boolean;
  has_fake_significance: boolean;
  metadata_tags: string[] | null;
}

export type ExperimentCutoff = {
  type: SignificanceType;
  cutoff: number;
  amount: number;
};

export enum SignificanceType {
  UNKNOWN = 0,
  ADJUSTED_SIGNIFICANCE = 1,
  SIGNIFICANCE = 2,
  MIMIC_SCORE = 3,
  MIMIC_EFFECT_SIZE = 4,
}

export interface PostSetCutoffData {
  experiment_id: string;
  cutoff_index: number;
}

export interface PostSetFavoriteToggle {
  experiment_id: string;
}

export interface PostExportGenericData {
  file_name: string;
  split: boolean;
  overlap_items: ExperimentQueryItem[];
}

export interface PostExportAnnotateData {
  query_item: AnnotateQueryItem;
  target_items: string[];
}

export interface AnnotateQueryItem {
  experiment_id: string;
  cutoff: ExperimentCutoffType;
}

export interface PostExportPrsData {
  file_name: string;
  target_experiment_id: string;
  link_experiment_id: string;
  r2_cutoff: number;
}

export type ExperimentQueryItem = {
  experiment_id: string;
  operation_type: ExperimentOverlapType;
  cutoff_type: ExperimentCutoffType;
};
export type ExperimentQuery = {
  items: ExperimentQueryItem[];
};

export interface ExperimentDataResponse {
  count: number;
  total: number;
  preview: ExperimentData[];
  component_details: ComponentDetails;
}

export interface ComponentDetails {
  component_sizes: {};
}

export interface ExperimentStatistics {
  data_count: number;
  data_linked_count: number;
}

export interface FileImportStatistics {
  error_samples: { [key: number]: string[][] };
  error_summary: { [key: number]: number }; // key: ImportLineError, number: no. of lines with error
  error_type: number;
  lines_error: ImportFileError;
  lines_imported: number;
  lines_skipped: number;
  lines_total: number;
}

export interface PostProcessingInfo {
  tag: ColumnTag;
  type: PostProcessingType;
  reason: PostProcessingReason;
}

export enum PostProcessingType {
  NONE = 0,
  LOG10TRANSFORM = 1,
}

export enum PostProcessingReason {
  UNKNOWN = 0,
  RANGE_NEG_INF = 1,
  RANGE_POS_INF = 2,
}

export interface ExperimentData {
  name: string;
  effect_size_classification: string;
  details: {
    name: string;
    data: { [key in keyof typeof ColumnTag]: string };
    match_type: string;
  }[];
}

export interface RequestRaw {
  id: string;
  file_name: string;
  split: boolean;
  request_type: RequestType;
  state: RequestStatus;
  files: RequestFileSet[];
  created: Date;
  updated: Date;
  overlap_items?: OverlapItemInfo[];
  link_experiment_id?: string;
  target_experiment_id?: string;
  r2_cutoff?: number;
  search_string?: string;
}

export interface RequestFileSet {
  file_path: string;
  file_name: string;
  file_size: number;
}

export enum RequestType {
  UNKNOWN = 0,
  OVERLAP_EXPORT = 1,
  ANNOTATE = 2,
  PRS_EXPORT = 3,
  SEARCH = 4,
}

export enum RequestStatus {
  NEW = 0,
  QUEUED = 1,
  PROCESSING = 2,
  SUCCEEDED = 3, // pre QA status
  ERROR = 4,
  CANCELLED = 5,
  APPROVED = 6,
}

export interface OverlapItemInfo {
  cutoff_type: ExperimentCutoffType;
  experiment_id: string;
  operation_type: ExperimentOverlapType;
}

export interface PostRequestFile {
  request_id: string;
  file_path: string;
  file_name: string;
}

export interface RequestFile {
  // the FileResponse from API
  requestFile: Blob;
}

export interface SearchQueryParameter {
  search_string: string;
}

export interface ExperimentDetails {
  metadata_tags: string[];
}

export interface SearchResult {
  request: SearchResultRequest;
  result: {
    [dataset_name: string]: SearchResultObservations[]; // dynamic properties
  };
  experiment_detail_map: { [experiment_name: string]: ExperimentDetails };
  status: SearchResultStatus;
}

export interface SearchResultRequest {
  search_string: string;
}

export interface SearchResultObservations {
  cut_off_type: string;
  hops: number;
  link_nodes: string[];
  origin_hit_primary_value: string;
  origin_hit_type: SearchResultObservationHitReason;
  origin_hit_uid: string;
  origin_hit_value: string;
  tags: { (tag: number): string };
}

export enum SearchResultStatus {
  UNKNOWN = 0,
  COMPLETED = 1,
  PARTIAL = 2,
  NONE = 3,
}

export enum SearchResultObservationHitReason {
  UNKNOWN = 0,
  PROTEIN = 1,
  PROTEIN_ACCESSION = 2,
  PROTEIN_ALIAS_ACCESSION = 3,
  ISOFORM = 10,
  ISOFORM_PRIMARY = 11,
  ISOFORM_SECONDARY = 12,
  GENE = 20,
  TRANSCRIPT = 30,
  GENE_SYMBOL = 40,
  GENE_SYMBOL_ALIAS = 41,
  RANGE = 50,
  VARIANT = 51,
}

export enum ReportState {
  Queued = 'Q',
  Generating = 'G',
  Ready = 'R',
}

export type Report =
  | {
      id?: string;
      state: ReportState.Generating | ReportState.Queued;
    }
  | {
      id: string;
      state: ReportState.Ready;
      url: string;
    };
