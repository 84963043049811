import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

// get all icons ideally from https://fluenticons.co

export const IntersectIcon = ({ className = undefined, width = 32, height = 20.625 }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 512 330"
    width={width}
    height={height}
    className={className}
  >
    <path
      fill="currentColor"
      d="M346,0c-33.2,0-64.1,9.9-90,26.8C230.1,9.9,199.2,0,166,0C75,0,1,74,1,165s74,165,165,165c33.2,0,64.1-9.9,90-26.8
        c25.9,16.9,56.8,26.8,90,26.8c91,0,165-74,165-165S437,0,346,0z M166,300c-74.4,0-135-60.6-135-135S91.6,30,166,30
        c23.6,0,45.8,6.1,65.1,16.7C200.2,76.7,181,118.7,181,165s19.2,88.3,50.1,118.3C211.8,293.9,189.6,300,166,300z M346,300
        c-23.6,0-45.8-6.1-65.1-16.7c30.9-30,50.1-71.9,50.1-118.3s-19.2-88.3-50.1-118.3C300.2,36.1,322.4,30,346,30
        c74.4,0,135,60.6,135,135S420.4,300,346,300z"
    />
  </svg>
);

export const SubtractionIcon = ({
  className = undefined,
  width = 32,
  height = 20.625,
}) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 512 330"
    width={width}
    height={height}
    className={className}
  >
    <path
      fill="currentColor"
      d="M346,0c-33.2,0-64.101,9.9-90,26.8C230.1,9.9,199.2,0,166,0C75,0,1,74,1,165s74,165,165,165c33.2,0,64.1-9.9,90-26.8
      	C281.9,320.1,312.8,330,346,330c91,0,165-74,165-165S437,0,346,0z M346,300c-8.206,0-16.236-0.753-24.041-2.161
      	c-0.002,0-0.005-0.001-0.007-0.001c-1.029-0.186-2.056-0.381-3.076-0.59c-0.306-0.062-0.608-0.128-0.912-0.192
      	c-0.835-0.177-1.671-0.352-2.501-0.544c-1.167-0.269-2.328-0.553-3.483-0.852c-0.217-0.057-0.431-0.118-0.647-0.175
      	c-1.121-0.296-2.236-0.607-3.347-0.931c-0.168-0.05-0.336-0.101-0.504-0.15c-55.813-16.523-96.536-68.177-96.536-129.349
      	c0-50.982,28.288-95.354,70.021-118.289c-0.023-0.021-0.045-0.044-0.067-0.066C300.199,36.1,322.4,30,346,30
      	c74.399,0,135,60.6,135,135C481,239.4,420.4,300,346,300z"
    />
  </svg>
);

export const StyledStarIcon = styled.div`
  fill: ${(props) => (props.filled ? themeGet('colors.gold') : 'none')};
  stroke: ${themeGet('colors.gold')};
  stroke-width: 2;
`;

export const StarIcon = ({
  className = undefined,
  width = 12,
  height = 12,
  filled = true,
}) => (
  <StyledStarIcon filled={filled}>
    <svg
      height={height}
      viewBox="-1 -1 22 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m8.788 1.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303l5.273-.766z" />
    </svg>
  </StyledStarIcon>
);

export const BlankIcon12by12 = ({ className = undefined, width = 12, height = 12 }) => (
  <StyledStarIcon filled={false}>
    <svg
      height={height}
      viewBox="-1 -1 22 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    ></svg>
  </StyledStarIcon>
);

export const IconBug = ({ className = undefined, width = 18, height = 18 }) => (
  <svg
    width={width}
    height={height}
    fill={themeGet('text')}
    viewBox="2 -2 22 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.5" cy="11.5" r="11.5" fill="#ffffff" />
    <path d="M12.002,15.004C11.991,15.004 11.981,15.004 11.97,15.004C11.422,15.004 10.971,15.454 10.971,16.003C10.971,16.551 11.422,17.002 11.97,17.002C11.981,17.002 11.991,17.001 12.002,17.001C12.538,16.984 12.969,16.539 12.969,16.003C12.969,15.466 12.538,15.021 12.002,15.004ZM12,7C11.495,7 11.066,7.383 11.007,7.884L11,8L11.002,13.001L11.009,13.118C11.069,13.619 11.498,14 12.002,14C12.506,14 12.935,13.619 12.995,13.118L13.002,13.001L13,8L12.993,7.883C12.934,7.382 12.505,7 12,7Z" />
  </svg>
);

export const IconProcessing = ({ className = undefined, width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.052 5.029a1 1 0 0 0 .189 1.401 7.002 7.002 0 0 1-3.157 12.487l.709-.71a1 1 0 0 0-1.414-1.414l-2.5 2.5a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 1.414-1.414l-.843-.842A9.001 9.001 0 0 0 17.453 4.84a1 1 0 0 0-1.401.189Zm-1.93-1.736-2.5-2.5a1 1 0 0 0-1.498 1.32l.083.094.843.843a9.001 9.001 0 0 0-4.778 15.892A1 1 0 0 0 7.545 17.4a7.002 7.002 0 0 1 3.37-12.316l-.708.709a1 1 0 0 0 1.32 1.497l.094-.083 2.5-2.5a1 1 0 0 0 .083-1.32l-.083-.094Z"
      fill="#212121"
    />
  </svg>
);

export const IconOK = ({ className = undefined, width = 12, height = 12 }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z"
      fill="#212121"
    />
  </svg>
);

export const IconFilter = ({ className = undefined, width = 12, height = 12 }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16h4a1 1 0 0 1 .117 1.993L14 18h-4a1 1 0 0 1-.117-1.993L10 16h4-4Zm-2-5h8a1 1 0 0 1 .117 1.993L16 13H8a1 1 0 0 1-.117-1.993L8 11h8-8ZM5 6h14a1 1 0 0 1 .117 1.993L19 8H5a1 1 0 0 1-.117-1.993L5 6h14H5Z"
      fill="#212121"
    />
  </svg>
);
