import * as React from 'react';

import * as T from './_types';

export const useMultiToggle: T.MultiToggleHook = (options, value, gap) => {
  const [containerWidth, setContainerWidth] = React.useState<number>(0);
  const ref = React.useRef<HTMLDivElement>(null);

  const selectionIndex = React.useMemo(
    () =>
      options.reduce(
        (acc, { value: comparisonValue }, index) =>
          value === comparisonValue ? index : acc,
        -1,
      ),
    [options, value],
  );

  React.useEffect(() => {
    const updateWidth = () => {
      if (ref.current === null) return;
      const { offsetWidth: containerWidth } = ref.current as HTMLDivElement;
      setContainerWidth(containerWidth);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return window.removeEventListener('resize', updateWidth);
  }, [ref, setContainerWidth]);

  const translationX = `calc((${selectionIndex} * ((${containerWidth}px - ${gap} * ${
    options.length - 1
  }) / ${options.length})) + ${gap} * ${selectionIndex})`;
  const width = `calc((100% - ${gap} * ${options.length - 1}) / ${
    options.length
  })`;

  return {
    width,
    ref,
    selectionIndex,
    translationX,
  };
};
