import * as T from '../components/CellMap/_types';
import { User } from '../store/user';
import {
  FilePatchData,
  FilePostData,
  DataSetFile,
  FilePostParameters,
} from '../components/CellMap/';

export const apiURL = window.env.API_URL;

const fetchResponse = async (
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> => {
  const response = await fetch(input, init);

  switch (response.status) {
    case 401:
    case 403:
      window.open('/logout', '_self');
      break;
  }

  return response;
};

const call = async (
  idToken: string,
  url: string,
  method: 'GET' | 'DELETE' | 'PATCH' | 'POST' | 'PUT',
  body?: string | FormData,
  additionalHeaders = {},
) => {
  const headers = {
    Authorization: `Bearer ${idToken}`,
    ...additionalHeaders,
  };
  const init = { method, headers, body };

  return await fetchResponse(url, init);
};

const post = async <R = any>(
  idToken: string,
  endpoint: string,
  payload: any,
  expectResponse: boolean = false,
): Promise<R | undefined> => {
  const response = await call(
    idToken,
    apiURL + endpoint,
    'POST',
    JSON.stringify(payload),
    {
      'Content-Type': 'application/json',
    },
  );
  if (!expectResponse) return;
  return (await response.json()) as R;
};

const del = async (idToken: string, endpoint: string) =>
  void (await call(idToken, apiURL + endpoint, 'DELETE'));

// TODO: improve handling of a defaultValue in case of a 404
const get = async <R>(
  idToken: string,
  endpoint: string,
  defaultValue404?: any,
): Promise<R> => {
  const response = await call(idToken, apiURL + endpoint, 'GET');
  if (defaultValue404 && response.status === 404) {
    return defaultValue404;
  }
  return (await response.json()) as R;
};

export const updateUserData = (idToken: string, userData: T.UserDataUpdate) =>
  post(idToken, 'users', userData);

export const deleteUserData = (idToken: string) => del(idToken, 'users');

export const readUserData = (idToken: string) => get<User>(idToken, 'user/me', null);

export const readRawFiles = (idToken: string) => get<T.RawFile[]>(idToken, `user/file`);

export const filePost = async (data: FilePostData) => {
  const parameters: FilePostParameters = {
    biotype: parseInt(data.formValues.biotype, 10),
    columnSelect: Object.fromEntries(
      Object.entries(data.formValues.columnSelect)
        .map(([key, value]) => [value, parseInt(key)])
        .filter(([key]) => !!key),
    ),
    description: data.formValues.description,
    name: data.formValues.name,
    metadata_tags: data.formValues.metadata_tags,
  };

  if (!!data.formValues.genomeAssembly) {
    parameters.genomeAssembly = parseInt(data.formValues.genomeAssembly, 10);
  }

  if (!!data.formValues.taxonomy) {
    parameters.taxonomy = parseInt(data.formValues.taxonomy, 10);
  }

  const response = await fetchResponse(
    `${apiURL}user/file/upload?file_name=${data.fileName}&file_size=${data.fileSize}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parameters),
    },
  );

  return response.json().then((data: DataSetFile) => ({
    status: response.status,
    dataSetFile: data,
  }));
};

export const filePatch = async (data: FilePatchData) => {
  const formData = new FormData();
  formData.append('file_content', data.blob, data.fileName);

  const response = await fetchResponse(
    `${apiURL}user/file/upload?file_name=${data.fileName}&file_size=${
      data.fileSize
    }&chunk_start=${data.chunkStart + 1}&chunk_end=${data.chunkEnd}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.idToken}`,
      },
      body: formData,
    },
  );

  return response.json().then((data) => ({
    status: response.status,
    dataSetFile: data,
  }));
};

export const passwordChange = async (data: T.PasswordChange) => {
  const response = await fetchResponse(`${apiURL}user/password`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${data.idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      old_password: data.passwordOld,
      new_password: data.passwordNew,
    }),
  });

  return response.json().then((data) => ({
    status: response.status,
    data: data,
  }));
};

export const requestPasswordReset = async (email: string) => {
  const response = await fetchResponse(`${apiURL}user/pwreset`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  });
  return response.json().then((data) => ({
    status: response.status,
    data: data,
  }));
};

export const setPassword = async (
  token: string,
  password: string,
): Promise<{ success: boolean; message: string }> => {
  const response = await fetch(`${apiURL}user/pwset`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
      password: password,
    }),
  });

  const result = await response.json();

  if (!response.ok) {
    if (response.status == 409) {
      throw new Error(result.detail + '. Please try again.');
    }

    throw new Error('Failed to reset password. ' + response.statusText);
  }

  return result;
};

export const requestSearchExport = async (
  idToken: string,
  search_string: string,
): Promise<{ success: boolean; message: string }> => {
  const response = await fetch(`${apiURL}user/request/search`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ search_string: search_string }),
  });

  if (response.ok) {
    return {
      success: true,
      message:
        'Export request has been sent and will become availabe in the Download center.',
    };
  } else {
    const result = await response.json();
    return {
      success: false,
      message: result.message || 'Error requesting the export request!',
    };
  }
};

export const deleteRawFile = (idToken: string, fileId: string) =>
  del(idToken, `user/file/delete/${fileId}`);

export const readExperiments = (idToken: string) =>
  get<T.ExperimentRaw[]>(idToken, `user/experiment`);

export const readJoinedExperimentData = (
  idToken: string,
  experimentQuery: T.ExperimentQuery,
) =>
  post<T.ExperimentDataResponse>(
    idToken,
    'user/experiment/overlap',
    experimentQuery,
    true,
  );

export const setCutoffLax = (idToken: string, postData: T.PostSetCutoffData) =>
  post(idToken, `user/experiment/set_lax_cutoff`, postData, false);

export const setCutoffStringent = (idToken: string, postData: T.PostSetCutoffData) =>
  post(idToken, `user/experiment/set_stringent_cutoff`, postData, false);

export const setFavoriteToggle = (idToken: string, postData: T.PostSetFavoriteToggle) =>
  get(idToken, `user/experiment/favorite/${postData.experiment_id}`);

export const exportGeneric = (idToken: string, postData: T.PostExportGenericData) =>
  post(idToken, `user/request/overlapexport`, postData, false);

export const exportPrs = (idToken: string, postData: T.PostExportPrsData) =>
  post(idToken, `user/request/prsexport`, postData, false);

export const getUserRequestList = (idToken: string) =>
  get<T.RequestRaw>(idToken, `user/requests`);

export const getUserRequestFile = (idToken: string, postData: T.PostRequestFile) =>
  post<T.RequestFile>(idToken, `user/requests/file`, postData, false);

export const downloadFile = (idToken: string, postData: T.PostRequestFile) =>
  fetch(
    apiURL +
      'user/requests/file?request_id=' +
      postData.request_id +
      '&file_path=' +
      postData.file_path,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  )
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', postData.file_name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
    });

export const fetchSearchResults = (idToken: string, postData: T.SearchQueryParameter) =>
  post<T.SearchResult>(idToken, `user/search`, postData, true);

// Deprecated
// export const getReport = (idToken: string, reportId: string) =>
//  get<T.Report>(idToken, `reports/${reportId}`);

// Deprecated
// export const createReport = (idToken: string, experimentQuery: T.ExperimentQuery) =>
//  post<T.Report>(idToken, 'reports', experimentQuery, true);
